import React from "react";
import MarkdownComponent from "../../MarkdownComponent";

function Antennit() {
  return (
    <div>
      <h1>Antennit</h1>
      <MarkdownComponent path="/RFkurssi/Antennit_md.md" />
    </div>
  );
}
export default Antennit;
