import React from "react";
import MarkdownComponent from "../../MarkdownComponent";

function Emaallot() {
  return (
    <div>
      <MarkdownComponent path="/RFkurssi/EM_aallot_md.md" />
    </div>
  );
}
export default Emaallot;
